export const GAS_LIMIT = {
    MAX: 6800000,
    OPEN_TROVE: 1200000,
    ADJUST_TROVE: 4000000,
    CLOSE_TROVE: 350000,
    CLOSE_DLLR_TROVE: 600000,
    CONVERT: 750000,
    STABILITY_POOL: 400000,
    STABILITY_POOL_INC_WITHDRAW: 490000,
    STABILITY_POOL_DLLR: 600000,
    STABILITY_POOL_DLLR_INC_WITHDRAW: 690000,
    REWARDS: 240000,
    REWARDS_CLAIM: 6800000,
    REWARDS_OS_FEE: 600000,
    TRANSFER_LOC: 900000,
    LENDING_MINT: 350000,
    LENDING_BURN: 450000,
    APPROVE: 60000,
    STAKING_STAKE: 1400000,
    STAKING_INCREASE_STAKE: 450000,
    STAKING_EXTEND: 450000,
    STAKING_WITHDRAW: 1400000,
    VESTING_DELEGATE: 4650000,
    SOV_WITHDRAW_VESTING: 6000000,
    SOV_WITHDRAW_VESTING_TEAM: 6700000,
    BORROW: 1500000,
    REPAY_LOAN: 950000,
    WITHDRAW_LOAN_COLLATERAL: 150000,
    GOVERNOR_PROPOSE: 1500000,
    PROPOSAL_VOTE: 300000,
    PROPOSAL_QUEUE: 250000,
    PROPOSAL_EXECUTE: 6000000,
    MARKET_MAKING_DEPOSIT: 6000000,
    MARKET_MAKING_ADD_LIQUIDITY: 550000,
    MARKET_MAKING_REMOVE_LIQUIDITY: 650000,
    MARKET_MAKING_CLAIM_FEES: 650000,
    MARKET_MAKING_REPOSITION: 600000,
    CLAIM_VESTED_SOV_REWARDS: 6000000,
    WITHDRAW_MARKET_MAKING_LIQUIDITY: 6000000,
};
